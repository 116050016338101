<template>
  <div class="nav">
    <a-button-group>
      <a-button class="nav-btn nav-btn-1">
        <router-link to="/weibo">
          Weibo
        </router-link>
      </a-button>
      <a-button class="nav-btn nav-btn-1">
        <router-link to="/msg">
          MSG
        </router-link>
      </a-button>
      <a-button class="nav-btn nav-btn-1">
        <router-link to="/live">
          直播
        </router-link>
      </a-button>
    </a-button-group>
  </div>
</template>

<style scoped>
.nav {
  text-align: center;
  margin-bottom: 20px;
}
.nav-btn {
  width: 100px;
}
</style>
