import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import regAntdv from './antdv';
import lazyPlugin from 'vue3-lazy';
import { formatDate } from './utils/date';

const app = createApp(App);

app.config.globalProperties.$filters = {
  dateFormat(val: string | number) {
    return formatDate(val);
  },
};

regAntdv(app);

app.use(lazyPlugin, {
  loading: '//static.hinpc.com/images/2022/icon-loading.svg',
  error: '//static.hinpc.com/images/2022/icon-error.svg',
});

app
  .use(store)
  .use(router)
  .mount('#app');
