import dayjs from 'dayjs';

// 支持传入日期格式：
// { string } 2020-09-14T09:59:18.000+0800
// { number } 1600066406000
export function formatDate(date: string | number, format = 'YYYY-MM-DD HH:mm:ss') {
  if (typeof date === 'number') {
    return dayjs(new Date(date)).format(format);
  } else {
    if (date.includes('T') && date.includes('+')) {
      return date.replace('T', ' ').substr(0, 19);
    }
    return dayjs(date).format(format);
  }
}

export function formatAndAddHour8(str: string) {
  return dayjs(new Date(str))
    .add(8, 'hour')
    .format('YYYY-MM-DD HH:mm');
}
