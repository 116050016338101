import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import Message from '../views/Message.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/live/play/:plat/:roomId',
    name: 'Player',
    component: () => import(/* webpackChunkName: "about" */ '../views/Live/Player.vue'),
  },
  {
    path: '/live/:plat',
    name: 'Live',
    component: () => import(/* webpackChunkName: "about" */ '../views/Live/index.vue'),
  },
  {
    path: '/live',
    redirect: '/live/douyu',
  },
  {
    path: '/s8',
    name: 'S8',
    component: () => import(/* webpackChunkName: "about" */ '../views/S8.vue'),
  },
  {
    path: '/weibo',
    name: 'Weibo',
    component: () => import(/* webpackChunkName: "about" */ '../views/Weibo.vue'),
  },
  {
    path: '/msg',
    name: 'Message',
    component: Message,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/',
    name: 'Home',
    // component: Home,
    redirect: '/weibo',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
