<template>
  <div>
    <Nav></Nav>
    <router-view></router-view>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue';

export default {
  components: { Nav },
};
</script>

<style lang="scss">
#app {
  font-family: -apple-system, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif,
    BlinkMacSystemFont, 'Helvetica Neue', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
    Arial;
  font-size: 14px;
  color: #333;
}
.mobile {
  width: 100%;
  max-width: 823px;
  margin: 0 auto;
}
</style>
