<template>
  <div class="page">
    <h2>推送测试</h2>
    <div>
      <a-input v-model:value="key" placeholder="密钥" v-on:keyup.enter="saveKey"></a-input>
      <a-button @click="saveKey">保存</a-button>
    </div>
    <div style="margin-top: 20px">
      <a-input v-model:value="receiver" placeholder="收件人邮箱(邮件推送必填)"></a-input>
      <a-input v-model:value="title" placeholder="标题"></a-input>
      <a-textarea v-model:value="content" placeholder="要发送的消息内容" auto-size />
    </div>
    <div>
      <a-button :loading="submitting" @click="wxSend">Sever酱推送</a-button>
      <a-button :loading="submitting" @click="mailSend">邮件推送</a-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import axios from 'axios';

const LOCALSTORAGENAME_KEY = 'case_key';
const KEY_VALIDATE = 'fff';

export default defineComponent({
  name: 'Message',
  setup() {
    const key = ref('');
    const receiver = ref('');
    const title = ref('');
    const content = ref('');
    const submitting = ref(false);

    const saveKey = (e: any) => {
      console.log(key);
      localStorage.setItem(LOCALSTORAGENAME_KEY, key.value);
    };
    const readKey = () => {
      const val = localStorage.getItem(LOCALSTORAGENAME_KEY);
      if (val) key.value = val;
    };
    const setSubmitting = (val: boolean) => {
      submitting.value = val;
    };

    onMounted(() => {
      (window as any).ttt = KEY_VALIDATE;
      readKey();
    });

    return {
      key,
      receiver,
      title,
      content,
      saveKey,
      readKey,
      submitting,
      setSubmitting,
    };
  },

  methods: {
    wxSend() {
      if (this.key !== KEY_VALIDATE) return;
      if (!this.title && !this.content) return;
      const title = this.title || '（未填写标题）';
      const content = this.content || '（未填写内容）';

      this.setSubmitting(true);
      axios
        .get(
          `https://sctapi.ftqq.com/SCT786TViEEk9Gsr1QQYrcW4SyUDmD4.send?title=${title}&desp=${content}`
        )
        .then(data => {
          this.setSubmitting(false);
          if (data.data?.data.errno === 0) {
            (this as any).$message.success('已发送');
          }
        })
        .finally(() => {
          this.setSubmitting(false);
        });
    },
    mailSend() {
      if (this.key !== KEY_VALIDATE) return;
      if (!this.receiver) return;
      if (!this.title && !this.content) return;
      const title = this.title || '（未填写标题）';
      const content = this.content || '（未填写内容）';

      this.setSubmitting(true);
      axios
        .request({
          url: 'http://vir.nicebook.win:13000/v1/sendmail',
          method: 'post',
          data: {
            to: this.receiver,
            from: 'AutoMail',
            title,
            content,
          },
          headers: {
            Authorization: 'basic aHZhbmtlOjY1NDMyMQ==',
          },
        })
        .then(data => {
          const result = data.data?.message;
          (this as any).$message.success(result);
        })
        .finally(() => {
          this.setSubmitting(false);
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.page {
  max-width: 600px;
  margin: 0 auto;
}
</style>
