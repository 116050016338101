import {
  Button,
  BackTop,
  Card,
  Input,
  Row,
  Col,
  DatePicker,
  List,
  Radio,
  Pagination,
  PageHeader,
  Spin,
  Tabs,
  message,
} from 'ant-design-vue';

export default function regAntdv(app: any) {
  app.use(Button);
  app.use(BackTop);
  app.use(Card);
  app.use(Input);
  app.use(Row);
  app.use(Col);
  app.use(Radio);
  app.use(DatePicker);
  app.use(List);
  app.use(Pagination);
  app.use(PageHeader);
  app.use(Spin);
  app.use(Tabs);
  app.config.globalProperties.$message = message;
}
